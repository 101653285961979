<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <img class="logo" :src="logoImage" height="50" alt="" />
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" :alt="$t('auth.welcome')" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { handleSSO } from '@/api/routes'

/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue"
import {
  BCol,
  BImg,
  BLink,
  BRow,
} from "bootstrap-vue"
import useJwt from "@/auth/jwt/useJwt"
import store from "@/store/index"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"
import { logoImageFunction } from '@/main'

export default {
  components: {
    BCol,
    BImg,
    BLink,
    BRow,
    VuexyLogo,
  },
  data() {
    return {
      sideImg: require("@/assets/images/pages/login/intro.png"),
      _orz9: null,
      _kolt: null,
      _akt: null,
      _l4ng: null,
      _3m4il: null,
    };
  },
  mounted() {
    this._orz9 = this.$route.query._orz9.replace(/\//g, '_').replace(/ /g, '-')
    this._kolt = this.$route.query._kolt.replace(/\//g, '_').replace(/ /g, '-')
    this._akt = this.$route.query._akt.replace(/\//g, '_').replace(/ /g, '-')
    this._l4ng = this.$route.query._l4ng.replace(/\//g, '_').replace(/ /g, '-')
    this._3m4il = this.$route.query._3m4il.replace(/\//g, '_').replace(/ /g, '-')
    this.sso()
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login/intro.png")
      }
      return this.sideImg
    },
    logoImage() {
      logoImageFunction();
    },
  },
  methods: {
    sso() {
      this.$http.post(handleSSO, {
        _orz9: this._orz9,
        _kolt: this._kolt,
        _akt: this._akt,
        _l4ng: this._l4ng,
        _3m4il: this._3m4il,
      }).then((response) => {
        if (response.data.status == 200) {
          const { userData, lang, redirect } = response.data

          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          this.$store.commit("user/setUser", userData)
          this.$store.commit("general/setLangs", response.data.langs)
          this.$ability.update(userData.ability)
          this.$i18n.locale = lang
          this.$store.commit('user/changeLang', lang)

          let target = getHomeRouteForLoggedInUser(userData.role)

          switch (redirect) {
            case "tutorials":
              target = '/resources'
              break
            case "password":
              target = '/account-setting?option=password'
              break
            case "settings":
              target = '/account-setting?option=general'
              break
            case "dreamers":
              target = '/dreamers'
              break;
            case "groups":
              target = '/groups'
              break;
            default:
              target = { name: 'main-home' }
              break
          }
          this.$router.push(target)
        } else {
          this.$router.push(`/login?error=${response.data.error_code}&status=403`)
            .catch((error) => {
              if (error.name !== 'NavigationDuplicated') {
                throw error;
              }
            });
          // this.$refs.loginForm.setErrors(response.data.message || response.data.msg)
        }
      }).catch((error) => {
        this.$router.push('/login?error=auth.error&status=403')
        // this.$refs.loginForm.setErrors(error.response.data.error)
      });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss"
</style>
